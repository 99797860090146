const locale = {
  TITLE: 'History Page (transactions)',
  
  DEVICES_TAB: 'Devices',
  BOTTLES_TAB: 'Bottles',

  TRANSACTIONS_PER_PAGE: 'Transactions per page',

  // TABLE
  TRANSACTION_ID: 'Trans. ID',
  SRC_COMPANY: 'From',
  DST_COMPANY: 'To',
  DATE: 'Date',

  DEVICE_UID: 'Device UID',
  DEVICE_NAME: 'Device Name',
  DEVICE_TYPE: 'Device Type',

  BOTTLE_UID: 'Bottle UID',
  LOT_CODE: 'Lot Code',
  LIQUID_TYPE: 'Liquid Type',
  RESIDUAL: 'Redidual (mL)',
};

export default locale;
