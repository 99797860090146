const locale = {
  TITLE: 'Pagina delle strutture',

  /* STRUCTURE PAGE */
  SEARCH: "Cerca",
  NEW_LOCATION: "Nuovo ambiente",
  ADDRESS: 'Indirizzo:',
  STRUCTURE_TYPE: "Tipologia di struttura:",
  CONTACT_DATA: "Informazioni di contatto:",

  /* STRUCTURE DIALOG */
  NEW_STRUCTURE_DIALOG_TITLE: "Aggiungi una struttura a ",
  FORM_NAME_LABEL: "Nome della struttura",
  FORM_NAME_HELPER: "Questo sarà il nome dell'attività commerciale o comunque identificativo della struttura stessa. Nel caso di un Hotel o di una farmacia, sarà il nome rispettivo dell'Hotel o della farmacia",
  FORM_TYPE_LABEL: "Tipo di struttura",
  FORM_ADDRESS_LABEL: "Indirizzo della struttura",
  FORM_ADDRESS_HELPER: "L'indirizzo della struttura dovrà essere nella forma: via, CAP, città, provincia, stato",
  FORM_EMAIL_LABEL: "Email di contatto della struttura",
  FORM_EMAIL_HELPER: "L'email di contatto viene richiesta per eventuali comunicazioni dirette verso la struttura.",
  FORM_DESCRIPTION_LABEL: "Descrizione della struttura",
  NEW_STRUCTURE_ERROR_MESSAGE: "Impossibile creare la struttura.",
  NEW_STRUCTURE_SUCCESS_MESSAGE: "Struttura creata correttamente.",

  /* LOCATIONS TABLE */
  NAME: 'Nome',
  UID: 'UID',
  VOLUME: 'Volume (m³)',
  EDIT_LOCATION: "Modifica l'ambiente",
  GENERATE_QR_CODE: 'Genera il codice QR',
  LOCATIONS_PER_PAGE: 'Ambienti per pagina',
  SORT: "Ordina",

  /* NEW LOCATION DIALOG */
  NEW_LOCATION_DIALOG_TITLE: "Aggiungi un ambiente a ",
  FORM_LOCATION_TYPE_LABEL: "Tipo di ambiente",
  FORM_LOCATION_NAME_LABEL: "Nome dell'ambiente",
  FORM_LOCATION_VOLUME_LABEL: "Volume dell'ambiente (m³)",
  FORM_LOCATION_VOLUME_HELPER: "Dimensione, in metri cubi, del locale o del veicolo.",
  FORM_LOCATION_DESCRIPTION_LABEL: "Descrizione dell'ambiente",
  NEW_LOCATION_ERROR_MESSAGE: "Impossibile creare l'ambiente.",
  NEW_LOCATION_SUCCESS_MESSAGE: "Ambiente creato correttamente.",

  /* LOCATION BADGE DIALOG */
  BADGE_LOCATION_TYPE_LABEL: "Tipologia Ambiente",
  BADGE_STRUCTURE_NAME_LABEL: "Nome Struttura",
  DOWNLOAD_PDF: "Download PDF",
  GENERATE_PDF: "Genera PDF",

  /* EDIT LOCATION DIALOG */
  EDIT_LOCATION_DIALOG_TITLE: "Modifica l'ambiente - ",
  EDIT_LOCATION_ERROR_MESSAGE: "Impossibile modificare l'ambiente.",
  EDIT_LOCATION_SUCCESS_MESSAGE: "Ambiente modificato correttamente.",
};

export default locale;
