const locale = {
  APPLICATIONS: 'Applications',
  DASHBOARD: 'Dashboard',
  SESSIONS: 'Sessions',
  MANAGEMENT: 'Management',
  NETWORK: 'Network',
  STRUCTURES: 'Structures',
  OPERATORS: 'Operators',
  DEVICES: 'Devices',
  LIQUID: 'Liquid',
  BOTTLES: 'Bottles',
  HISTORY: 'History',
};

export default locale;
