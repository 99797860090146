import { authCapabilityMasks, authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './navigation-i18n/en';
import it from './navigation-i18n/it';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('it', 'navigation', it);

const navigationConfig = [  

  {
    id: 'dashboards-component',
    title: 'Dashboards',    
    type: 'group',
    icon: 'dashboard',
    
    auth: {
      roles: authRoles.user,
    },
    children: [
      {
        id: 'analytics-dashboard',
        title: 'Analytics',    
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard',
        auth: {
          roles: authRoles.user,
        },
      },
      {
        id: 'devices-dashboard',
        title: 'Devices',    
        type: 'item',
        icon: 'device_hub',
        url: '/devpanel',
        auth: {
          roles: authRoles.admin,
        },
      },      
      {
        id: 'bottles-dashboard',
        title: 'Bottles',    
        type: 'item',
        icon: 'sanitizer',
        url: '/botpanel',
        auth: {
          roles: authRoles.admin,
        },
      },   
      {
        id: 'operators-dashboard',
        title: 'Operators',    
        type: 'item',
        icon: 'group',
        url: '/oppanel',
        auth: {
          roles: authRoles.admin,
        },
      },   
      {
        id: 'structures-dashboard',
        title: 'Structures',    
        type: 'item',
        icon: 'home',
        url: '/strpanel',
        auth: {
          roles: authRoles.admin,
        },
      }, 
    ]
  },
  {
    id: 'sessions-component',
    title: 'Sessions',
    translate: 'SESSIONS',
    type: 'item',
    icon: 'list_alt',
    url: '/sessions',
    auth: {
      roles: authRoles.user,
      capabilities: [authCapabilityMasks.endCustomer],
    },
  },
  {    
    id: 'abc',
    type: 'divider',    
  },
  {
    id: 'management',
    title: 'Management',
    translate: 'MANAGEMENT',
    type: 'group',
    icon: 'settings',
    auth: {
      roles: authRoles.admin,
    },
    children: [
      {
        id: 'network-component',
        title: 'Network',
        translate: 'NETWORK',
        type: 'item',
        icon: 'public',
        url: '/management/network',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.deviceDistributor, authCapabilityMasks.bottleDistributor],
        },
      },
      {
        id: 'structures-component',
        title: 'Structures',
        translate: 'STRUCTURES',
        type: 'item',
        icon: 'eco',
        url: '/management/structures',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.endCustomer],
        },
      },
      {
        id: 'operators-component',
        title: 'Operators',
        translate: 'OPERATORS',
        type: 'item',
        icon: 'groups',
        url: '/management/operators',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.endCustomer],
        },
      },
      {
        id: 'devices-component',
        title: 'Devices',
        translate: 'DEVICES',
        type: 'item',
        icon: 'crop_square',
        url: '/management/devices',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.endCustomer, authCapabilityMasks.deviceProducer, authCapabilityMasks.deviceDistributor],
        },
      },
      {
        id: 'bottles-component',
        title: 'Bottles',
        translate: 'BOTTLES',
        type: 'item',
        icon: 'sanitizer',
        url: '/management/bottles',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.endCustomer, authCapabilityMasks.bottleProducer, authCapabilityMasks.bottleDistributor],
        },
      },
      {
        id: 'liquid-component',
        title: 'Liquid',
        translate: 'LIQUID',
        type: 'item',
        icon: 'opacity',
        url: '/management/liquid',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.bottleProducer],
        },
      },
      {
        id: 'history-component',
        title: 'History',
        translate: 'HISTORY',
        type: 'item',
        icon: 'history',
        url: '/history',
        auth: {
          roles: authRoles.admin,
          capabilities: [authCapabilityMasks.deviceDistributor, authCapabilityMasks.bottleDistributor],
        },
      },
    ]
  },
];

export default navigationConfig;
