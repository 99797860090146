const locale = {
  TITLE: 'Storico delle operazioni (transazioni)',
  
  DEVICES_TAB: 'Dispositivi',
  BOTTLES_TAB: 'Flaconi',

  TRANSACTIONS_PER_PAGE: 'Transazioni per pagina',

  // TABLE
  TRANSACTION_ID: 'ID Trans.',
  SRC_COMPANY: 'Da',
  DST_COMPANY: 'A',
  DATE: 'Data',

  DEVICE_UID: 'UID del Disp.',
  DEVICE_NAME: 'Nome del Disp.',
  DEVICE_TYPE: 'Tipo di Disp.',

  BOTTLE_UID: 'UID del Flacone',
  LOT_CODE: 'Codice Lotto',
  LIQUID_TYPE: 'Tipo di Liquido',
  RESIDUAL: 'Residuo (mL)',
};

export default locale;
