import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import Invitation from './Invitation';

i18next.addResourceBundle('en', 'invitationPage', en);
i18next.addResourceBundle('it', 'invitationPage', it);

const InvitationConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: {
        roles: authRoles.onlyGuest,
    },
    routes: [
        {
            path: '/invitation',
            component: Invitation,
        },
    ],
};

export default InvitationConfig;
