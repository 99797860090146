const locale = {
  /* HEADER */
  TITLE: 'Bottles Page',
  SEARCH: 'Search by lot code',
  ASSIGN: 'Assign',
  OWN_BOTTLES_TAB: 'Own bottles',
  TO_BE_ASSIGNED_BOTTLES_TAB: 'Bottles to be assigned',

  /* TABLE */
  UID: 'UID',
  LOT_CODE: 'Lot Code',
  LIQUID_TYPE: 'Liquid Type',
  RESIDUAL: 'Residual',
  SORT: 'Sort',
  BOTTLE_INFO: 'Bottle info',
  LAST_TRANSACTION_TYPE: 'Last transaction type',
  BOTTLES_PER_PAGE: 'Bottles per page',

  /* ASSIGN BOTTLES DIALOG */
  ASSIGN_BOTTLE_DIALOG_TITLE: 'Assignment of a bottle',
  ASSIGN_BOTTLES_DIALOG_TITLE: 'Assignment of ',
  BOTTLES: 'Bottles',
  FORM_TRANSACTION_TYPE_LABEL: 'Transaction type',
  FORM_REFERENCE_CODE_LABEL: 'Transaction reference code',
  FORM_COMPANY_NAME_LABEL: "Recipient",
  FORM_NAME_HELPER: "Select a company among those shown",
  ASSIGN_BOTTLE_ERROR_MESSAGE: "Unable to perform the assignment.",
  ASSIGN_BOTTLE_SUCCESS_MESSAGE: "Assignment completed.",
  
  /* BOTTLE DETAILS */
  TRANSACTION_REFERENCE_CODE_LABEL: 'Reference code',
  TRANSACTION_TYPE_LABEL: 'Transaction type',
  TRANSACTION_DATE_LABEL: 'Date',
  TRANSACTION_SRC_COMPANY_LABEL: 'Source Company',
  TRANSACTION_DST_COMPANY_LABEL: 'Destination Company',

  BOTTLE_TYPE_DESCRIPTION: 'Bottle description',
  CAPACITY: 'Capacity',
  LIQUID_LOT_CODE: 'Lot code',
  LIQUID_TYPE_DESCRIPTION: 'Liquid description',
  UNIQUE_IDENTIFIER: 'Unique identifier',
  EXIPIRE_DATE: 'Expire date',

  /* FILTERS */
  FILTER_ALL_BOTTLES: "All bottles",
};

export default locale;
