import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import { authRoles } from 'app/auth';
import { lazy } from 'react';

i18next.addResourceBundle('en', 'userProfilePage', en);
i18next.addResourceBundle('it', 'userProfilePage', it);

const UserProfileConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: {
        roles: authRoles.user,
    },
    routes: [
        {
            path: '/profile/:option?',
            component: lazy(() => import('./UserProfile'))
        }
    ]
};

export default UserProfileConfig;
