const locale = {
  TITLE: 'Structures Page',

  /* STRUCTURE PAGE */
  SEARCH: "Search by name",
  NEW_LOCATION: "New location",
  ADDRESS: 'Address:',
  STRUCTURE_TYPE: "Structure type:",
  CONTACT_DATA: "Contact data:",

  /* STRUCTURE DIALOG */
  NEW_STRUCTURE_DIALOG_TITLE: "Add a structure to ",
  FORM_NAME_LABEL: "Structure name",
  FORM_NAME_HELPER: "This will be the name of the business or an identifier of the facility itself. In the case of a hotel or pharmacy, this will be the respective name of the hotel or pharmacy.",
  FORM_TYPE_LABEL: "Structure type",
  FORM_ADDRESS_LABEL: "Structure address",
  FORM_ADDRESS_HELPER: "The address of the facility should be in the form: street, ZIP code, city, county, state",
  FORM_EMAIL_LABEL: "Structure contact email",
  FORM_EMAIL_HELPER: "The contact email is required for any direct communications to the facility. It can be omitted.",
  FORM_DESCRIPTION_LABEL: "Structure description",
  NEW_STRUCTURE_ERROR_MESSAGE: "Unable to create the structure.",
  NEW_STRUCTURE_SUCCESS_MESSAGE: "Structure successfully created.",

  /* LOCATIONS TABLE */
  NAME: 'Name',
  UID: 'UID',
  VOLUME: 'Volume (m³)',
  EDIT_LOCATION: 'Edit the location',
  GENERATE_QR_CODE: 'Generate the QR Code',
  LOCATIONS_PER_PAGE: 'Locations per page',
  SORT: "Sort",

  /* NEW LOCATION DIALOG */
  NEW_LOCATION_DIALOG_TITLE: "Add a location to ",
  FORM_LOCATION_TYPE_LABEL: "Location type",
  FORM_LOCATION_NAME_LABEL: "Location name",
  FORM_LOCATION_VOLUME_LABEL: "Location volume (m³)",
  FORM_LOCATION_VOLUME_HELPER: "Size, in cubic yards, of the premises or vehicle.",
  FORM_LOCATION_DESCRIPTION_LABEL: "Location description",
  NEW_LOCATION_ERROR_MESSAGE: "Unable to create the location.",
  NEW_LOCATION_SUCCESS_MESSAGE: "Location successfully created.",

  /* LOCATION BADGE DIALOG */
  BADGE_LOCATION_TYPE_LABEL: "Location Type",
  BADGE_STRUCTURE_NAME_LABEL: "Stucture Name",
  DOWNLOAD_PDF: "Download PDF",
  GENERATE_PDF: "Generate PDF",

  /* EDIT LOCATION DIALOG */
  EDIT_LOCATION_DIALOG_TITLE: "Edit location - ",
  EDIT_LOCATION_ERROR_MESSAGE: "Unable to edit the location.",
  EDIT_LOCATION_SUCCESS_MESSAGE: "Location succesfully edited.",
};

export default locale;
