const locale = {
  COMPANY_DETAILS: "Dettagli dell'azienda",
  OPERATOR_DETAILS: "Dettagli dell'operatore amministratore",
  DATA_CHECK: "Controllo dei dati",

  FORM_COMPANY_NAME_LABEL: "Ragione sociale",
  INV_CODE_HELPER: "Codice ricevuto con la mail di invito",
  FORM_VAT_ID_LABEL: "Partita IVA",
  FORM_ADDRESS_LABEL: "Indirizzo della sede legale",

  FORM_OPERATOR_NAME_LABEL: "Nome dell'operatore",
  FORM_CONTACT_EMAIL_LABEL: "Email di contatto",
  CONTACT_EMAIL_HELPER: "L'email di contatto verrà utilizzata anche come credenziale di accesso",
  FORM_PHONE_LABEL: "Numero di telefono",
  FORM_PASSWORD_LABEL: "Password",
  FORM_PASSWORD_CONFIRMATION_LABEL: "Conferma password",

  COMPANY_NAME: "Ragione sociale",
  VAT_ID: "Partita IVA",
  ADDRESS: "Indirizzo della sede legale",
  ADMIN_NAME: "Nome dell'amministratore",
  ADMIN_EMAIL: "Email dell'amministratore",
  FORM_INV_CODE_LABEL: "Codice d'invito",

  BACK: 'Indietro',
  NEXT: 'Avanti',
  FINISH: 'Fine',
};

export default locale;
