import { combineReducers } from '@reduxjs/toolkit';
import invitation from './invitationSlice';
import form from './formSlice';

const reducer = combineReducers({
  invitation,
  form,
});

export default reducer;
