import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled, ThemeProvider, useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseNavigation from '@fuse/core/FuseNavigation/FuseNavigation';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { selectContrastMainTheme } from 'app/store/fuse/settingsSlice';
import { useLocation } from 'react-router-dom';
import NavbarUserMenu from 'app/fuse-layouts/shared-components/NavbarUserMenu';
import Divider from '@mui/material/Divider';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const StyledPanel = styled(FuseScrollbars)(({ theme, opened }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.primary,
  transition: theme.transitions.create(['opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  opacity: 0,
  pointerEvents: 'none',
  ...(opened && {
    opacity: 1,
    pointerEvents: 'initial',
  }),
}));

function needsToBeOpened(location, item) {
  return location && isUrlInChildren(item, location.pathname);
}

function isUrlInChildren(parent, url) {
  if (!parent.children) {
    return false;
  }

  for (let i = 0; i < parent.children.length; i += 1) {
    if (parent.children[i].children) {
      if (isUrlInChildren(parent.children[i], url)) {
        return true;
      }
    }

    if (parent.children[i].url === url) {
      return true;
    }
  }

  return false;
}

function NavbarStyle3Content(props) {
  const navigation = useSelector(selectNavigation);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedNavigation, setSelectedNavigation] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.main));
  const location = useLocation();

  useEffect(() => {
    navigation?.forEach((item) => {
      if (needsToBeOpened(location, item)) {
        setSelectedItem([item]);
        setSelectedNavigation([item]);
      }
    });
  }, [navigation, location]);

  function handleParentItemClick(selected) {
    /** if there is no child item do not set/open panel
     */
    if (!selected.children) {
      setSelectedItem([selected]);
      setPanelOpen(false);
      if (mdDown) {
        dispatch(navbarCloseMobile());
      }
      return;
    }

    /**
     * If navigation already selected toggle panel visibility
     */
    if (selectedItem[0]?.id === selected.id) {
      setPanelOpen(!panelOpen);
      setSelectedItem([selected]);
    } else {
      /**
       * Set navigation and open panel
       */
      setSelectedNavigation([selected]);
      setPanelOpen(true);
    }
  }

  function handleChildItemClick(selected) {
    setSelectedItem(selectedNavigation);
    setPanelOpen(false);
    if (mdDown) {
      dispatch(navbarCloseMobile());
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setPanelOpen(false)}>
      <Root className={clsx('flex flex-auto flex h-full', props.className)}>
        <ThemeProvider theme={contrastTheme}>
          <div id="fuse-navbar-side-panel" className="flex flex-shrink-0 flex-col items-center justify-center">
            <div className='w-44 my-32'>
              {/*
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dice-d6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
                <path fill="currentColor" d="M422.19 109.95L256.21 9.07c-19.91-12.1-44.52-12.1-64.43 0L25.81 109.95c-5.32 3.23-5.29 11.27.06 14.46L224 242.55l198.14-118.14c5.35-3.19 5.38-11.22.05-14.46zm13.84 44.63L240 271.46v223.82c0 12.88 13.39 20.91 24.05 14.43l152.16-92.48c19.68-11.96 31.79-33.94 31.79-57.7v-197.7c0-6.41-6.64-10.43-11.97-7.25zM0 161.83v197.7c0 23.77 12.11 45.74 31.79 57.7l152.16 92.47c10.67 6.48 24.05-1.54 24.05-14.43V271.46L11.97 154.58C6.64 151.4 0 155.42 0 161.83z" />
              </svg>
              */}
            </div>
            <FuseScrollbars
              className="flex flex-1 min-h-0 justify-center w-full overflow-y-auto overflow-x-hidden items-center"
              option={{ suppressScrollX: true, wheelPropagation: false }}
            >
              <FuseNavigation
                className={clsx('navigation')}
                navigation={navigation}
                layout="vertical-2"
                onItemClick={handleParentItemClick}
                firstLevel
                selectedId={selectedItem[0]?.id}
                dense={props.dense}
              />
            </FuseScrollbars>

            <div className="flex flex-col items-center my-32 gap-8">
              <Divider className="w-full mb-12" />
              <NavbarUserMenu />
            </div>
          </div>
        </ThemeProvider>

        {panelOpen && selectedNavigation.length > 0 && selectedNavigation[0].children?.length > 0 && (
          <StyledPanel
            id="fuse-navbar-panel"
            opened={panelOpen}
            className={clsx('shadow-5 overflow-y-auto overflow-x-hidden')}
            option={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <FuseNavigation
              className={clsx('navigation')}
              navigation={selectedNavigation}
              layout="vertical"
              onItemClick={handleChildItemClick}
            />
          </StyledPanel>
        )}

      </Root>
    </ClickAwayListener>
  );
}

export default memo(NavbarStyle3Content);
