import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import { authCapabilityMasks, authRoles } from 'app/auth';

i18next.addResourceBundle('en', 'structuresPage', en);
i18next.addResourceBundle('it', 'structuresPage', it);

const StructuresConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: {
        roles: authRoles.admin,
        capabilities: [authCapabilityMasks.endCustomer],
    },
    routes: [
        {
            path: '/management/structures/:structureId',
            component: React.lazy(() => import('./Structure')),
        },
        {
            path: '/management/structures',
            component: React.lazy(() => import('./Structures'))
        }
    ]
};

export default StructuresConfig;
