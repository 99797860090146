import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { Button, FormControl, Icon, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleBack, handleNext, setCompanyData, setOperatorData } from "../store/formSlice";
import { useState } from "react";

/* Form Validation Schema */
const schema = yup.object().shape({
    name: yup.string().required('You must enter the company name'),
    email: yup.string().required('You must enter a email'),
    phone: yup.string().required('Please enter the phone number.'),
    password: yup.string().required('Please enter the password.'),
    confirmationPassword: yup.string()
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
        }),
});

function SecondStep(props) {
    const { t } = useTranslation('invitationPage');
    const dispatch = useDispatch();
    const operatorData = useSelector(state => state.invitationPage.form.operatorData);
    const { handleSubmit, formState, control } = useForm({
        mode: 'all',
        defaultValues: { ...operatorData, confirmationPassword: '', },
        resolver: yupResolver(schema),
    });
    const { isValid, errors } = formState;
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

    function getUserLangId() {
        var userLang = window.navigator.userLanguage || window.navigator.language;
        return userLang?.split('-')[0] || DEFAULT_LANGUAGE;
    }

    const onSubmit = (data) => {
        delete data['confirmationPassword'];

        dispatch(setOperatorData({
            ...data,
            language: getUserLangId()
        }));
        dispatch(handleNext());
    }

    const onBackClick = () => {
        dispatch(handleBack());
    }

    return (
        <form className="flex flex-col h-full" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ flex: 1, overflowY: "auto" }}>
                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_OPERATOR_NAME_LABEL')}
                                error={!!errors.name}
                                helperText={errors?.name?.message}
                                required
                                color="secondary"
                                variant="filled"
                            />
                        )}
                    />
                </FormControl>

                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_CONTACT_EMAIL_LABEL')}
                                error={!!errors.email}
                                helperText={errors?.email?.message}
                                required
                                color="secondary"
                                variant="filled"
                                InputProps={{
                                    endAdornment:
                                        <Tooltip title={t('CONTACT_EMAIL_HELPER')}>
                                            <Icon className="mx-8" color="action" style={{ cursor: 'default' }}>help_outline</Icon>
                                        </Tooltip>
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl className="mt-8 mb-16" required fullWidth>
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <>
                                <TextField
                                    {...field}
                                    label={t('FORM_PHONE_LABEL')}
                                    error={!!errors.phone}
                                    helperText={errors?.phone?.message}
                                    required
                                    color="secondary"
                                    variant="filled"
                                />
                            </>
                        )}
                    />
                </FormControl>

                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_PASSWORD_LABEL')}
                                error={!!errors.password}
                                helperText={errors?.password?.message}
                                required
                                color="secondary"
                                variant="filled"
                                InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                                                <Icon className="text-20" color="action">
                                                    {showPassword ? 'visibility' : 'visibility_off'}
                                                </Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl className="mt-8 mb-16" required fullWidth>
                    <Controller
                        name="confirmationPassword"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_PASSWORD_LABEL')}
                                error={!!errors.confirmationPassword}
                                helperText={errors?.confirmationPassword?.message}
                                required
                                color="secondary"
                                variant="filled"
                                InputProps={{
                                    type: showConfirmationPassword ? 'text' : 'password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setShowConfirmationPassword(!showConfirmationPassword)} size="large">
                                                <Icon className="text-20" color="action">
                                                    {showConfirmationPassword ? 'visibility' : 'visibility_off'}
                                                </Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </FormControl>

            </div>

            <div className="flex grow-0 shrink-0 basis-auto w-full justify-between">
                <Button
                    color="inherit"
                    disabled={false}
                    onClick={onBackClick}
                    color="secondary"
                    variant="outlined"
                >
                    <Icon className="text-24 flex">
                        navigate_before
                    </Icon>
                    <span className="ml-8 mr-4">{t('BACK')}</span>
                </Button>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={!isValid}
                >
                    <span className="ml-8 mr-4">{t('NEXT')}</span>
                    <Icon className="text-24 flex">
                        navigate_next
                    </Icon>
                </Button>
            </div>
        </form>
    )
}

export default SecondStep;
