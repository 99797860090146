/**
 * Authorization Roles
 * ADMIN
 * USER
 * (ROOT)
 */

 const authRoles = {
  admin: [0],
  user: [0, 1],
  onlyGuest: [],
}

export default authRoles;
