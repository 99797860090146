import { Card, Step, StepLabel, Stepper } from "@mui/material";
import { motion } from 'framer-motion';
import withReducer from "app/store/withReducer";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import reducer from "./store";
import { getInvitation } from "./store/invitationSlice";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import FirstStep from "./steps/FirstStep";
import { setInvitationToken } from "./store/formSlice";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function InvitationPage(props) {
    const { t } = useTranslation('invitationPage');
    let query = useQuery();
    const token = query.get("token");
    const dispatch = useDispatch();
    const invitation = useSelector(({ invitationPage }) => invitationPage.invitation.data);
    const form = useSelector(state => state.invitationPage.form);
    const steps = [t('COMPANY_DETAILS'), t('OPERATOR_DETAILS'), t('DATA_CHECK')];

    useEffect(() => {
        if (invitation) {
            /* Invitation token is not null --> VALID (save in the form state) */
            dispatch(setInvitationToken(invitation.inv_token))
        }
    }, [invitation]);

    useEffect(() => {
        dispatch(getInvitation(token));
    }, [dispatch]);

    return (
        <div className="w-full h-screen" style={{ overflowX: 'scroll' }}>
            <div className="h-full w-md md:w-lg m-auto" style={{ padding: '48px 32px' }}>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.2 } }}
                    className="flex w-full h-full rounded-20 shadow-lg overflow-hidden"
                >
                    <Card className="flex flex-col w-full h-full items-center justify-between" style={{ padding: '48px 48px 24px 48px' }}>

                        {/* CARD HEADER */}
                        <div className="grow-0 shrink-0 basis-auto w-full">
                            <Stepper alternativeLabel activeStep={form.activeStep}>
                                {steps.map(label => {
                                    return (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>

                        {/* CARD CONTENT */}
                        <div className="w-full mt-40" style={{ flex: 1, overflowY: "auto" }}>
                            {{
                                0: <FirstStep />,
                                1: <SecondStep />,
                                2: <ThirdStep />,
                            }[form.activeStep]}
                        </div>

                    </Card>
                </motion.div>
            </div>
        </div>
    );
}

export default withReducer('invitationPage', reducer)(InvitationPage);
