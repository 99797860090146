/**
 * 0x01: cliente finale, operativo
 * 0x02: distributore di dispositivi
 * 0x04: distributore di flaconi
 * 0x08: produttore di flaconi
 * 0x10: produttore di dispositivi
 * 0x20: super company
 */

const authCapabilityMasks = {
    endCustomer: 1,
    deviceDistributor: 2,
    bottleDistributor: 4,
    bottleProducer: 8,
    deviceProducer: 16,
    superCompany: 32,
}

export const checkCapability = (capabilityMask, userCapabilities) => (capabilityMask & userCapabilities) ? true : false

export default authCapabilityMasks;
