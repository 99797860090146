const locale = {
  /* HEADER */
  TITLE: 'Pagina dei dispositivi',
  SEARCH: 'Cerca per nome o numero seriale',
  ASSIGN: 'Assegna',
  OWN_DEVICES_TAB: 'Dispositivi propri',
  TO_BE_ASSIGNED_DEVICES_TAB: 'Dispositivi da assegnare',

  /* TABLE */
  SORT: 'Ordina',
  NAME: 'Nome',
  MODEL: 'Modello',
  SERIAL_NUMBER: 'Numero Seriale',
  DEVICE_INFO: 'Informazioni del dispostivo',
  LAST_TRANSACTION_TYPE: 'Tipo ultima transazione',
  DEVICES_PER_PAGE: 'Dispositivi per pagina',
  STATUS: "Stato",

  /* ASSIGN DEVICE DIALOG */
  ASSIGN_DEVICE_DIALOG_TITLE: 'Assegnazione di un dispositivo',
  ASSIGN_DEVICES_DIALOG_TITLE: 'Assegnazione di ',
  DEVICES: 'Dispositivi',
  FORM_TRANSACTION_TYPE_LABEL: 'Tipo di transazione',
  FORM_REFERENCE_CODE_LABEL: 'Codice di riferimento transazione',
  FORM_COMPANY_NAME_LABEL: "Destinatario",
  FORM_NAME_HELPER: "Seleziona un'azienda tra quelle mostrate",
  ASSIGN_DEVICE_ERROR_MESSAGE: "Impossibile eseguire l'assegnazione.",
  ASSIGN_DEVICE_SUCCESS_MESSAGE: "Assegnazione completata.",

  /* DEVICE DETAILS */
  TRANSACTION_REFERENCE_CODE_LABEL: 'Codice di riferimento transazione',
  FW_VERSION: "Versione firmware",
  BT_CODE: "Codice Bluetooth",
  UNIQUE_IDENTIFIER: "Identificatore univoco",
  REGISTRATION_DATE: "Data di registrazione",
  TRANSACTION_TYPE: "Tipologia di transazione",
  TRANSACTION_DATE: "Data",

  /* FILTERS */
  FILTER_ALL_DEVICES: "Tutti i dispositivi",
};

export default locale;
