const locale = {
  TITLE: 'Pagina Profilo Utente',

  PERSONAL_INFO: 'Informazioni Personali',
  GLOBAL_PREFERENCES: 'Preferenze Globali',
  NOTIFICATIONS: 'Notifiche',

  PREFERRED_LANGUAGE: 'Lingua preferita',
  ITALIAN: 'Italiano',
  ENGLISH: 'Inglese',
  SPANISH: 'Spagnolo',
  GERMAN: 'Tedesco',
  EDIT: 'Modifica',
  CANCEL: 'Annulla',
  SAVE: 'Salva',

  EMAIL: 'Email',
  COMPANY_NAME: "Nome dell'azienda",
  SANI_CODE: 'SANICode',
  COMPANY_CAPABILITIES: "Capacità dell'azienda",
  
  END_CUSTOMER: 'Cliente Finale',
  DEVICE_DISTRIBUTOR: "Distributore di dispositivi",
  DEVICE_PRODUCER: "Produttore di dispositivi",
  BOTTLE_DISTRIBUTOR: "Distributore di flaconi",
  BOTTLE_PRODUCER: "Produttore di flaconi",
  SUPER_COMPANY: "Super Company",
};

export default locale;
