import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { Button, FormControl, Icon, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleNext, setCompanyData } from "../store/formSlice";

/* Form Validation Schema */
const schema = yup.object().shape({
    name: yup.string().required('You must enter the company name'),
    vat_id: yup.string().required('You must enter the company VAT Id'),
    address: yup.string().required('You must enter the office address'),
});

function FirstStep(props) {
    const { t } = useTranslation('invitationPage');
    const dispatch = useDispatch();
    const companyData = useSelector(state => state.invitationPage.form.companyData);
    const { handleSubmit, formState, control } = useForm({
        mode: 'all',
        defaultValues: companyData,
        resolver: yupResolver(schema),
    });
    const { isValid, errors } = formState;

    const onSubmit = (data) => {
        dispatch(setCompanyData(data));
        dispatch(handleNext());
    }

    return (
        <form className="flex flex-col h-full" onSubmit={handleSubmit(onSubmit)}>
            <div style={{ flex: 1, overflowY: "auto" }}>
                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_COMPANY_NAME_LABEL')}
                                error={!!errors.name}
                                helperText={errors?.name?.message}
                                required
                                color="secondary"
                                variant="filled"
                            />
                        )}
                    />
                </FormControl>
                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="vat_id"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_VAT_ID_LABEL')}
                                error={!!errors.vat_id}
                                helperText={errors?.vat_id?.message}
                                required
                                color="secondary"
                                variant="filled"
                            />
                        )}
                    />
                </FormControl>
                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_ADDRESS_LABEL')}
                                error={!!errors.address}
                                helperText={errors?.address?.message}
                                required
                                color="secondary"
                                variant="filled"
                            />
                        )}
                    />
                </FormControl>
            </div>

            <div className="flex grow-0 shrink-0 basis-auto w-full justify-between">
                <Button
                    color="inherit"
                    disabled={true}
                    color="secondary"
                    variant="outlined"
                >
                    <Icon className="text-24 flex">
                        navigate_before
                    </Icon>
                    <span className="ml-8 mr-4">{t('BACK')}</span>
                </Button>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={!isValid}
                >
                    <span className="ml-8 mr-4">{t('NEXT')}</span>
                    <Icon className="text-24 flex">
                        navigate_next
                    </Icon>
                </Button>
            </div>
        </form>
    )
}

export default FirstStep;
