import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';

function NavbarUserMenu(props) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);

    const [userMenu, setUserMenu] = useState(null);

    const userMenuClick = (event) => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (
        <>
            <Button
                className="px-0 md:px-16 py-0 md:py-6"
                style={{
                    minHeight: 100,
                    height: 100,
                    width: 100,
                    borderRadius: 12,
                    margin: '0 0 4px 0',
                }}
                onClick={userMenuClick}
                color="inherit"
            >
                <div className="flex flex-col mx-4 items-center gap-10">
                    {
                        user
                        && (user.photoURL && <Avatar className="md:mx-4" alt="user photo" src={user.photoURL} />)
                        || (user.name && <Avatar className="md:mx-4" style={{ background: "#4F46E5", color: "white" }}>{user.name[0]}</Avatar>)
                    }
                    {/*
                    <Typography component="span" className="font-semibold flex">
                        {user ? user.name : ''}
                    </Typography>
                    */}
                    <Typography className="text-11 font-medium capitalize" color="textSecondary">
                        {user.role_name}
                    </Typography>
                </div>
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'py-8',
                }}
            >
                {(!user.role_id && user.role_id !== 0) || user.role_id.length === 0 ? (
                    <>
                        <MenuItem component={Link} to="/login" role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>lock</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Login" />
                        </MenuItem>
                        <MenuItem component={Link} to="/register" role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>person_add</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Register" />
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem component={Link} to="/profile/info" onClick={userMenuClose} role="button">
                            <ListItemIcon className="min-w-40">
                                <Icon>account_circle</Icon>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                dispatch(logoutUser());
                                userMenuClose();
                            }}
                        >
                            <ListItemIcon className="min-w-40">
                                <Icon>exit_to_app</Icon>
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </MenuItem>
                    </>
                )}
            </Popover>
        </>
    );
}

export default NavbarUserMenu;
