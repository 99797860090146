import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ApiService from "app/services/apiService";
import history from '@history';

export const getInvitation = createAsyncThunk('invitationPage/invitation/getInvitation', async (invitationToken) => {
    const response = await ApiService.getInvitation(invitationToken);
    if (response.data.return !== 0) {
        // invalid token, redirect to login page
        history.replace({
            pathname: '/login',
            state: { status: 1, message: 'INVALID_TOKEN' }
        });
    }
    const data = response.data.data;
    if (data.inv_status === 1) {
        history.replace({
            pathname: '/login',
            state: { status: 1, message: 'USED_TOKEN' }
        });
    }
    return data;
});

const initialState = {
    loading: false,
    data: null,
}

const invitationSlice = createSlice({
    name: 'invitationPage/invitation',
    initialState,
    reducers: {},
    extraReducers: {
        [getInvitation.pending]: (state) => {
            state.loading = true
        },
        [getInvitation.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.data = payload
        },
        [getInvitation.rejected]: (state) => {
            state.loading = false
        },
    },
});

export default invitationSlice.reducer;