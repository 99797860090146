const indigo = {
    50: '#EEF2FF',
    100: '#E0E7FF',
    200: '#C7D2FE',
    300: '#A5B4FC',
    400: '#818CF8',
    500: '#6366F1',
    600: '#4F46E5',
    700: '#4338CA',
    800: '#3730A3',
    900: '#312E81',
    A100: '#8C9EFF',
    A200: '#536DFE',
    A400: '#3D5AFE',
    A700: '#304FFE',
    contrastDefaultColor: 'dark',
};

export default indigo;
