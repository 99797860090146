const locale = {
  APPLICATIONS: 'Applicazioni',
  DASHBOARD: 'Dashboard',
  SESSIONS: 'Sessioni',
  MANAGEMENT: 'Gestione',
  NETWORK: 'Network',
  STRUCTURES: 'Strutture',
  OPERATORS: 'Operatori',
  DEVICES: 'Dispositivi',
  LIQUID: 'Liquido',
  BOTTLES: 'Flaconi',
  HISTORY: 'Storico',
};

export default locale;