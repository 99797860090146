import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: undefined,
  type: undefined,
  open: false,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, { payload }) => {
      state.type = payload.type;
      state.message = payload.message;
      state.open = true;
    },
    closeSnackbar: (state, action) => {
      state.open = false;
    }
  },
  extraReducers: {},
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
