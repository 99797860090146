const locale = {
  TITLE: 'Sessions Page',
  OPERATOR: 'Operator',
  LOCATION: 'Location',
  STATUS: 'Status',
  DATE: 'Date',
  SESSIONS_PER_PAGE: 'Sessions per page',
  REPORT_DOWNLOAD: 'Download the report',
  SORT: "Sort",
};

export default locale;
