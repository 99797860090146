import { authRoles } from 'app/auth';
import Login from './Login';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';

i18next.addResourceBundle('en', 'loginPage', en);
i18next.addResourceBundle('it', 'loginPage', it);

const LoginConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: {
    roles: authRoles.onlyGuest,
  },
  routes: [
    {
      path: '/login',
      component: Login,
    },
  ],
};

export default LoginConfig;
