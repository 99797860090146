const locale = {
  TITLE: 'User Profile Page',
  
  PERSONAL_INFO: 'Personal Information',
  GLOBAL_PREFERENCES: 'Global Preferences',
  NOTIFICATIONS: 'Notifications',

  PREFERRED_LANGUAGE: 'Preferred Language',
  ITALIAN: 'Italian',
  ENGLISH: 'English',
  SPANISH: 'Spanish',
  GERMAN: 'German',
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  
  EMAIL: 'Email',
  COMPANY_NAME: 'Company Name',
  SANI_CODE: 'SANICode',
  COMPANY_CAPABILITIES: 'Company Capabilities',

  END_CUSTOMER: 'End Customer',
  DEVICE_DISTRIBUTOR: "Devices Distributor",
  DEVICE_PRODUCER: "Devices Producer",
  BOTTLE_DISTRIBUTOR: "Bottles Distributor",
  BOTTLE_PRODUCER: "Bottles Producer",
  SUPER_COMPANY: "Super Company",
  
};

export default locale;
