const locale = {
  TITLE: 'Network Page',

  /* HEADER */
  ADD_COMPANY: 'Connetti azienda',
  INVITE_COMPANY: 'Invita azienda',

  /* COMPANY TABLE */
  SUB_COMPANIES_PER_PAGE: 'Sotto-aziende per pagina',
  SORT: 'Ordina',
  NAME: 'Ragione sociale',
  ADDRESS: 'Indirizzo',
  VAT_ID: 'Partita IVA',
  COMPANY_CODE: 'SaniCODE',

  /* CONNECT COMPANY DIALOG */
  CONNECT_COMPANY: 'Connetti azienda',
  CONNECT_COMPANY_DIALOG_TITLE: "Connettiti a un'azienda",
  FORM_COMPANY_NAME_LABEL: "Nome Azienda",
  FORM_NAME_HELPER: "Ricerca un'azienda per nome e selezionala tra quelle mostrate",
  CONNECT: 'Connetti',
  CONNECTION_ERROR_MESSAGE: "Impossibile effettuare la connessione.",
  CONNECTION_SUCCESS_MESSAGE: "Connessione avvenuta con successo.",

  /* INVITE COMPANY DIALOG */
  INVITE_COMPANY_DIALOG_TITLE: "Invita un'azienda",
  FORM_EMAIL_LABEL: "Email",
  FORM_COMPANY_CAPABILITIES_LABEL: "Capacità dell'azienda",
  FORM_MESSAGE_LABEL: "Messaggio opzionale",
  INVITE: "Invita",
  INVITATION_ERROR_MESSAGE: "Impossibile inviare l'invito.",
  INVITATION_SUCCESS_MESSAGE: "Invito inviato correttamente.",

  /* COMMON LABELS */
  COMPANY_NAME_LABEL: 'Nome azienda',
};

export default locale;
