const locale = {
  /* HEADER */
  TITLE: 'Devices Page',
  SEARCH: 'Search by name or serial number',
  ASSIGN: 'Assign',
  OWN_DEVICES_TAB: 'Own devices',
  TO_BE_ASSIGNED_DEVICES_TAB: 'Devices to be assigned',

  /* TABLE */
  SORT: 'Sort',
  NAME: 'Name',
  MODEL: 'Model',
  SERIAL_NUMBER: 'Serial Number',
  DEVICE_INFO: 'Device info',
  LAST_TRANSACTION_TYPE: 'Last transaction type',
  DEVICES_PER_PAGE: 'Devices per page',
  STATUS: "Status",

  /* ASSIGN DEVICES DIALOG */
  ASSIGN_DEVICE_DIALOG_TITLE: 'Assignment of a device',
  ASSIGN_DEVICES_DIALOG_TITLE: 'Assignment of ',
  DEVICES: 'Devices',
  FORM_TRANSACTION_TYPE_LABEL: 'Transaction type',
  FORM_REFERENCE_CODE_LABEL: 'Transaction reference code',
  FORM_COMPANY_NAME_LABEL: "Recipient",
  FORM_NAME_HELPER: "Select a company among those shown",
  ASSIGN_DEVICE_ERROR_MESSAGE: "Unable to perform the assignment.",
  ASSIGN_DEVICE_SUCCESS_MESSAGE: "Assignment completed.",

  /* DEVICE DETAILS */
  TRANSACTION_REFERENCE_CODE_LABEL: 'Transaction reference code',
  FW_VERSION: "Firmware version",
  BT_CODE: "Bluetooth code",
  UNIQUE_IDENTIFIER: "Unique Identifier",
  REGISTRATION_DATE: "Registration date",
  TRANSACTION_TYPE: "Transaction type",
  TRANSACTION_DATE: "Date",

  /* FILTERS */
  FILTER_ALL_DEVICES: "All devices",
};


export default locale;
