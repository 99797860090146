import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import DashboardConfig from 'app/main/dashboard/DashboardConfig';
import SessionsConfig from 'app/main/sessions/SessionsConfig';
import NetworkConfig from 'app/main/management/network/NetworkConfig';
import StructuresConfig from 'app/main/management/structures/StructuresConfig';
import OperatorsConfig from 'app/main/management/operators/OperatosConfig';
import DevicesConfig from 'app/main/management/devices/DevicesConfig';
import LiquidConfig from 'app/main/management/liquid/LiquidConfig';
import BottlesConfig from 'app/main/management/bottles/BottlesConfig';
import HistoryConfig from 'app/main/management/history/HistoryConfig';
import FuseLoading from '@fuse/core/FuseLoading';
import Error404Page from 'app/main/404/Error404Page';
import LoginConfig from 'app/main/login/LoginConfig';
import InvitationConfig from 'app/main/invitation/InvitationConfig';
import UserProfileConfig from 'app/main/user-profile/UserProfileConfig';
import DevpanelConfig from 'app/main/devpanel/DevpanelConfig';
import BotpanelConfig from 'app/main/botpanel/BotpanelConfig';
import OppanelConfig from 'app/main/oppanel/OppanelConfig';
import StrpanelConfig from 'app/main/strpanel/StrpanelConfig';

const routeConfigs = [
  DashboardConfig,
  SessionsConfig,
  NetworkConfig,
  StructuresConfig,
  DevicesConfig,
  OperatorsConfig,
  LiquidConfig,
  BottlesConfig,
  HistoryConfig,
  LoginConfig,
  InvitationConfig,
  UserProfileConfig,
  DevpanelConfig,
  BotpanelConfig,
  OppanelConfig,
  StrpanelConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: '/loading',
    exact: true,
    component: () => <FuseLoading />,
  },
  {
    path: '/404',
    component: () => <Error404Page />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
