const locale = {
  TITLE: 'Pagina dei liquidi',
  SEARCH_BY_LOT_CODE: 'Cerca per codice lotto',
  ADD_LIQUID: 'Aggiungi liquido',
  SUPPLIERS: 'Fornitori',

  /* Liquid table */
  LIQUID_PER_PAGE: 'Liquidi per pagina',
  LOT_CODE: 'Codice lotto',
  TYPE_NAME: 'Tipo liquido',
  SUPPLIER: 'Fornitore',
  STATUS: 'Stato',
  EXPIRATION: 'Scadenza',
  DERIVED: 'Derivato',
  REMAINING: 'Rimanente',
  LIQUID_DILUTED: 'Il liquido è diluito',
  MORE_DETAILS: 'Maggiori dettagli',
  DILUTE_LIQUID: 'Diluisci il liquido',
  GENERATE_BOTTLE: 'Genera flacone',

  /* Liquid dialog */
  NEW_LIQUID_DIALOG_TITLE: 'Aggiungi un lotto di liquido a ',
  FORM_LIQUID_SUPPLIER_LABEL: 'Fornitore di liquido',
  FORM_LIQUID_TYPE_LABEL: 'Tipo di liquido',
  FORM_LOT_CODE_LABEL: 'Codice del lotto',
  FORM_LIQUID_VOLUME_LABEL: 'Volume (mL)',
  NEW_LIQUID_ERROR_MESSAGE: "Impossibile creare il liquido.",
  NEW_LIQUID_SUCCESS_MESSAGE: "Liquido creato correttamente.",

  /* Dilute liquid dialog */
  DILUTE_LIQUID_DIALOG_TITLE: 'Diluisci il lotto di liquido',
  AVAILABLE_LIQUID: 'Liquido disponibile',
  FORM_EXPIRATION_DATE_LABEL: 'Data di scadenza',
  FORM_USE_DEFAULT_EXPIRE_DATE_LABEL: 'Usa data di scadenza del lotto originario',
  DILUTE_LIQUID_ERROR_MESSAGE: "Impossibile diluire il liquido.",
  DILUTE_LIQUID_SUCCESS_MESSAGE: "Liquido diluito correttamente.",
  DILUTE: 'Diluisci',

  /* Liquid suppliers page */
  SUPPLIERS_TITLE: 'Pagina dei fornitori',
  ADD_SUPPLIER: 'Aggiungi fornitore',
  SUPPLIERS_PER_PAGE: 'Fornitori per pagina',
  SUPPLIER_NAME: 'Nome fornitore',
  SUPPLIER_VAT_ID: 'Partita IVA', 
  SEARCH_BY_NAME: 'Cerca per nome',

  /* Liquid supplier dialog */
  ADD_SUPPLIER_DIALOG_TITLE: 'Aggiungi un fornitore di liquido',
  FORM_VAT_ID_LABEL: 'Partita IVA',
  FORM_BUSINESS_NAME_LABEL: 'Ragione sociale',
  ADD_SUPPLIER_ERROR_MESSAGE: "Impossibile aggiungere il fornitore.",
  ADD_SUPPLIER_SUCCESS_MESSAGE: "Fornitore aggiunto correttamente.",

  /* Generate bottles dialog */
  GENERATE_BOTTLES_DIALOG_TITLE: "Genera flaconi",
  GENERATE_BOTTLES_DIALOG_HEADER: "Continua sullo smartphone",
  GENERATE_BOTTLES_DIALOG_DETAILS: "Accedi alla app con le tue credenziali, scansiona il QR code qua di fianco e inizia a leggere i tag RFID dei flaconi.",
  GENERATE_BOTTLES_TOKEN_ERROR_MESSAGE: "Impossibile generare il codice QR.",

  /* Liquid details */
  LIQUID_TRANSACTION_DESTINATION_TYPE: "Tipo di destinazione",
  LIQUID_TRANSACTION_DATE: "Data",
  LIQUID_TRANSACTION_ORIGINAL_VOLUME: "Volume originale (mL)",
  LIQUID_TRANSACTION_FINAL_VOLUME: "Volume finale (mL)",
  LIQUID_TRANSACTION_DESTINATION_LOT: "Lotto di Destinazione",
  LIQUID_TRANSACTION_DILUTION: "Diluizione",
  LIQUID_TRANSACTION_BOTTLING: "Imbottigliamento",

  DESCRIPTION: "Descrizione",
  DILUTION: "Diluizione",
  COMPOSITION: "Composizione",
  VOLUME: "Volume",
  ADD_TIME: "Data di inserimento",
  EXPIRE_DATE: "Data di scadenza",
};

export default locale;
