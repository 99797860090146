const locale = {
  TITLE: 'Operators Page',
  EMAIL: 'Email',
  NAME: 'Name',
  UID: 'UID',
  PHONE: 'Phone',
  ROLE: 'Role',
  EDIT_OPERATOR: 'Edit the operator',
  OPERATOR_BADGE: 'Operator Badge',
  OPERATORS_PER_PAGE: 'Operators per page',
  SORT: "Sort",
  SEARCH: "Search by name",
  NEW_OPERATOR: "New operator",

  /* OPERATOR DIALOG */
  NEW_OPERATOR_DIALOG_TITLE: "Add an operator to ",
  FORM_OPERATOR_NAME_LABEL: "Name",
  FORM_OPERATOR_ROLE_LABEL: "Role",
  FORM_OPERATOR_EMAIL_LABEL: "Email",
  FORM_OPERATOR_EMAIL_HELPER: "The contact email will also be used as login credential",
  FORM_OPERATOR_PHONE_LABEL: "Phone number",
  FORM_OPERATOR_EMAIL_CONFIRMATION_LABEL: "Contact email confirmation",
  FORM_OPERATOR_PASSWORD_LABEL: "Password",
  FORM_OPERATOR_PASSWORD_CONFIRMATION_LABEL: "Password confirmation",
  NEW_OPERATOR_ERROR_MESSAGE: "Unable to create the operator.",
  NEW_OPERATOR_SUCCESS_MESSAGE: "Operator succesfully created.",
  
  /* EDIT OPERATOR DIALOG */
  EDIT_OPERATOR_DIALOG_TITLE: "Edit operator",
  EDIT_OPERATOR_ERROR_MESSAGE: "Unable to edit the operator.",
  EDIT_OPERATOR_SUCCESS_MESSAGE: "Operator succesfully edited.",

  /* OPERATOR BADGE */
  COMPANY_NAME: 'Company Name',
  SANI_CODE: 'SANICode',
  DOWNLOAD: 'Download',
  DOWNLOAD_PDF: 'Download PDF',
  GENERATE_PDF: 'Generate PDF',
};

export default locale;
