const locale = {
  /* HEADER */
  TITLE: 'Pagina dei flaconi',
  SEARCH: 'Cerca per codice lotto',
  ASSIGN: 'Assegna',
  OWN_BOTTLES_TAB: 'Flaconi propri',
  TO_BE_ASSIGNED_BOTTLES_TAB: 'Flaconi da assegnare',

  /* TABLE */
  UID: 'UID',
  LOT_CODE: 'Codice Lotto',
  LIQUID_TYPE: 'Tipo di Liquido',
  RESIDUAL: 'Residuo (mL)',
  SORT: 'Ordina',
  BOTTLES_PER_PAGE: 'Flaconi per pagina',
  LAST_TRANSACTION_TYPE: 'Tipo ultima transazione',
  BOTTLE_INFO: 'Informazioni flacone',

  /* ASSIGN BOTTLES DIALOG */
  ASSIGN_BOTTLE_DIALOG_TITLE: 'Assegnazione di un flacone',
  ASSIGN_BOTTLES_DIALOG_TITLE: 'Assegnazione di ',
  BOTTLES: 'Flaconi',
  FORM_TRANSACTION_TYPE_LABEL: 'Tipo di transazione',
  FORM_REFERENCE_CODE_LABEL: 'Codice di riferimento transazione',
  FORM_COMPANY_NAME_LABEL: "Destinatario",
  FORM_NAME_HELPER: "Seleziona un'azienda tra quelle mostrate",
  ASSIGN_BOTTLE_ERROR_MESSAGE: "Impossibile eseguire l'assegnazione.",
  ASSIGN_BOTTLE_SUCCESS_MESSAGE: "Assegnazione completata.",

  /* BOTTLE DETAILS */
  TRANSACTION_REFERENCE_CODE_LABEL: 'Codice di riferimento',
  TRANSACTION_TYPE_LABEL: 'Tipo di transazione',
  TRANSACTION_DATE_LABEL: 'Data',
  TRANSACTION_SRC_COMPANY_LABEL: 'Azienda sorgente',
  TRANSACTION_DST_COMPANY_LABEL: 'Azienda destinazione',
  
  BOTTLE_TYPE_DESCRIPTION: 'Descrizione flacone',
  CAPACITY: 'Capacità',
  LIQUID_LOT_CODE: 'Codice lotto',
  LIQUID_TYPE_DESCRIPTION: 'Descrizione liquido',
  UNIQUE_IDENTIFIER: 'Identificatore univoco',
  EXIPIRE_DATE: 'Data di scadenza',

  
  /* FILTERS */
  FILTER_ALL_BOTTLES: "Tutti i flaconi",
};

export default locale;
