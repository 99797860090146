import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import { authCapabilityMasks, authRoles } from 'app/auth';

i18next.addResourceBundle('en', 'operatorsPage', en);
i18next.addResourceBundle('it', 'operatorsPage', it);

const OperatorsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: {
        roles: authRoles.admin,
        capabilities: [authCapabilityMasks.endCustomer],
    },
    routes: [
        {
            path: '/management/operators',
            component: React.lazy(() => import('./Operators'))
        }
    ]
};

export default OperatorsConfig;
