import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import { authCapabilityMasks, authRoles } from 'app/auth';

i18next.addResourceBundle('en', 'liquidPage', en);
i18next.addResourceBundle('it', 'liquidPage', it);

const LiquidConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: {
        roles: authRoles.admin,
        capabilities: [authCapabilityMasks.bottleProducer],
    },
    routes: [
        {
            path: '/management/liquid/suppliers',
            component: React.lazy(() => import('./suppliers/Suppliers')),
        },
        {
            path: '/management/liquid/:liquidId',
            component: React.lazy(() => import('./liquidDetails/LiquidDetails'))
        },
        {
            path: '/management/liquid',
            component: React.lazy(() => import('./Liquid'))
        }
    ]
};

export default LiquidConfig;
