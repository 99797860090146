const locale = {
  TITLE: 'Network Page',

  /* HEADER */
  CONNECT_COMPANY: 'Connect company',
  INVITE_COMPANY: 'Invite company',

  /* COMPANY TABLE */
  SUB_COMPANIES_PER_PAGE: 'Sub-companies per page',
  SORT: 'Sort',
  NAME: 'Name',
  ADDRESS: 'Address',
  VAT_ID: 'VAT ID',
  COMPANY_CODE: 'SaniCODE',

  /* CONNECT COMPANY DIALOG */
  CONNECT_COMPANY: 'Connect company',
  CONNECT_COMPANY_DIALOG_TITLE: 'Connect to company',  
  FORM_COMPANY_NAME_LABEL: "Company Name",
  FORM_NAME_HELPER: 'Search a company by name and select it from those shown',
  CONNECT: 'Connect',
  CONNECTION_ERROR_MESSAGE: "Unable to connect.",
  CONNECTION_SUCCESS_MESSAGE: "Successful connection.",

  /* INVITE COMPANY DIALOG */
  INVITE_COMPANY_DIALOG_TITLE: "Invite a new company",
  FORM_EMAIL_LABEL: "Email",
  FORM_COMPANY_CAPABILITIES_LABEL: "Company capabilities",
  FORM_MESSAGE_LABEL: "Optional message",
  INVITE: "Invite",
  INVITATION_ERROR_MESSAGE: "Unable to send invitation.",
  INVITATION_SUCCESS_MESSAGE: "Invitation sent correctly.",

  /* COMMON LABELS */
  FORM_COMPANY_NAME_LABEL: 'Company name',
};

export default locale;
