import { Controller, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { Button, FormControl, Icon, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { completeRegistration, handleBack, setInvitationCode } from "../store/formSlice";
import { useHistory } from "react-router-dom";

/* Form Validation Schema */
const schema = yup.object().shape({
    inv_code: yup.string().required('You must enter the invitation code you received by email'),
});

function ThirdStep(props) {
    const { t } = useTranslation('invitationPage');
    const history = useHistory()
    const dispatch = useDispatch();
    const companyData = useSelector(state => state.invitationPage.form.companyData);
    const operatorData = useSelector(state => state.invitationPage.form.operatorData);
    const { handleSubmit, formState, control } = useForm({
        mode: 'all',
        defaultValues: { inv_code: '' },
        resolver: yupResolver(schema),
    });
    const { isValid, errors } = formState;

    const onSubmit = (data) => {
        dispatch(setInvitationCode(data.inv_code));
        dispatch(completeRegistration()).then(() => {
            history.push({
                pathname: '/login',
                state: { status: 0, message: 'REGISTRATION_COMPLETED' }
            });
        });
    }

    const onBackClick = () => {
        dispatch(handleBack());
    }

    return (
        <form className="flex flex-col h-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col w-full" style={{ flex: 1, overflowY: "auto" }}>
                <div style={{ marginBottom: '24px', marginLeft: '12px' }}>
                    <p className="mb-12"><span className="opacity-60 mr-8">{t('COMPANY_NAME')}</span>{companyData.name}</p>
                    <p className="mb-12"><span className="opacity-60 mr-8">{t('VAT_ID')}</span>{companyData.vat_id}</p>
                    <p className="mb-12"><span className="opacity-60 mr-8">{t('ADDRESS')}</span>{companyData.address}</p>
                    <p className="mb-12"><span className="opacity-60 mr-8">{t('ADMIN_NAME')}</span>{operatorData.name}</p>
                    <p className="mb-12"><span className="opacity-60 mr-8">{t('ADMIN_EMAIL')}</span>{operatorData.email}</p>
                </div>

                <FormControl className="mb-16" required fullWidth>
                    <Controller
                        name="inv_code"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={t('FORM_INV_CODE_LABEL')}
                                error={!!errors.inv_code}
                                helperText={errors?.inv_code?.message}
                                required
                                color="secondary"
                                variant="filled"
                                InputProps={{
                                    endAdornment:
                                        <Tooltip title={t('INV_CODE_HELPER')}>
                                            <Icon className="mx-8" color="action" style={{ cursor: 'default' }}>help_outline</Icon>
                                        </Tooltip>
                                }}
                            />
                        )}
                    />
                </FormControl>
            </div>

            <div className="flex grow-0 shrink-0 basis-auto w-full justify-between">
                <Button
                    color="inherit"
                    disabled={false}
                    onClick={onBackClick}
                    color="secondary"
                    variant="outlined"
                >
                    <Icon className="text-24 flex">
                        navigate_before
                    </Icon>
                    <span className="ml-8 mr-4">{t('BACK')}</span>
                </Button>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={!isValid}
                >
                    <span className="ml-8 mr-4">{t('FINISH')}</span>
                </Button>
            </div>
        </form>
    );
}

export default ThirdStep;
