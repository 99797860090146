import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import store from "app/store";
import apiService from 'app/services/apiService';

export const completeRegistration = createAsyncThunk('invitationPage/invitation/completeRegistration', async () => {
    const form = store.getState().invitationPage.form;

    const response = await apiService.completeRegistration(
        {
            inv_token: form.inv_token,
            inv_code: form.inv_code,
            company_data: form.companyData,
            user_data: form.operatorData
        }
    );
    const data = await response.data.data;
    return data;
});

const initialState = {
    stepNumber: 3,
    activeStep: 0,
    inv_token: '',
    inv_code: '',
    companyData: {
        name: '',
        vat_id: '',
        address: '',
        notes: '',
    },
    operatorData: {
        name: '',
        email: '',
        phone: '',
        password: '',
    }
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        handleBack: (state, action) => { state.activeStep > 0 && state.activeStep-- },
        handleNext: (state, action) => { state.activeStep < state.stepNumber && state.activeStep++ },

        setInvitationToken: (state, action) => { state.inv_token = action.payload },
        setInvitationCode: (state, action) => { state.inv_code = action.payload },

        setCompanyData: (state, action) => { state.companyData = action.payload },
        setOperatorData: (state, action) => { state.operatorData = action.payload },
    }
})

export const { handleBack, handleNext, setInvitationToken, setInvitationCode, setCompanyData, setOperatorData } = formSlice.actions;

export default formSlice.reducer;
