import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import { authRoles } from 'app/auth';

i18next.addResourceBundle('en', 'strpanelPage', en);
i18next.addResourceBundle('it', 'strpanelPage', it);


const StrpanelConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: {
        roles: authRoles.user,
    },
    routes: [        
        {
            path: '/strpanel',
            component: React.lazy(() => import('./Strpanel'))
        }
    ]
};

export default StrpanelConfig;
