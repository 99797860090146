import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import { resetError, submitLogin } from 'app/auth/store/loginSlice';
import { useHistory } from 'react-router-dom';
import SnackbarMessage from '../shared-components/SnackbarMessage';
import { useTranslation } from 'react-i18next';
import { openSnackbar } from '../shared-components/store/snackbarSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().required('You must enter a email'),
  password: yup
    .string()
    .required('Please enter your password.')
});

const defaultValues = {
  email: '',
  password: '',
};

function Login() {
  const { t } = useTranslation('loginPage');
  const history = useHistory();
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // REDIRECT TO LOGIN - CHECK MESSAGE
    const message = history.location.state?.message;
    if (message) {
      const status = history.location.state?.status;
      let snackbarType;
      let snackbarMessage;
      if (status !== 0) {
        snackbarType = "ERROR";
        switch (message) {
          case 'INVALID_TOKEN':
            snackbarMessage = "Invalid registration token";
            break;
          case 'USED_TOKEN':
            snackbarMessage = "Token already used";
            break;
          default:
            snackbarMessage = "Error";
        }
      } else if (status === 0) {
        snackbarType = "SUCCESS";
        switch (message) {
          case 'REGISTRATION_COMPLETED':
            snackbarMessage = "Registration completed";
            break;
          default:
            snackbarMessage = "Success";
        }
      }
      dispatch(openSnackbar({
        type: snackbarType,
        message: snackbarMessage,
      }))
    }
  }, [history]);

  useEffect(() => {
    reset();
    if (login.error) {
      dispatch(openSnackbar({
        type: "ERROR",
        message: t(login.error),
      }));
      dispatch(resetError());
    }
  }, [login.error]);

  function onSubmit(model) {
    dispatch(submitLogin(model));
  }

  return (
    <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
      <div className="flex flex-col items-center justify-center w-full">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1 }}
          className="flex w-full max-w-sm rounded-20 shadow-2xl overflow-hidden"
        >
          <Card className="flex flex-col w-full items-center justify-center shadow-0" >
            <CardContent className="flex flex-col items-center justify-center w-full pt-68 pb-68 max-w-320" style={{ padding: '68px 0px' }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.2 } }}
              >
                <div className="flex items-center mb-68 gap-12">
                  {/*
                  <div className='w-40'>                    
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dice-d6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
                      <path fill="currentColor" d="M422.19 109.95L256.21 9.07c-19.91-12.1-44.52-12.1-64.43 0L25.81 109.95c-5.32 3.23-5.29 11.27.06 14.46L224 242.55l198.14-118.14c5.35-3.19 5.38-11.22.05-14.46zm13.84 44.63L240 271.46v223.82c0 12.88 13.39 20.91 24.05 14.43l152.16-92.48c19.68-11.96 31.79-33.94 31.79-57.7v-197.7c0-6.41-6.64-10.43-11.97-7.25zM0 161.83v197.7c0 23.77 12.11 45.74 31.79 57.7l152.16 92.47c10.67 6.48 24.05-1.54 24.05-14.43V271.46L11.97 154.58C6.64 151.4 0 155.42 0 161.83z" />
                    </svg>
                  </div>
                  */}
                  <div>
                    <Typography className="text-5xl font-semibold logo-text" color="inherit">
                      SaniView
                    </Typography>
                  </div>
                </div>
              </motion.div>

              <div className="w-full">
                <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-16"
                        type="text"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        label="Email"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className="text-20" color="action">
                                user
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        variant="filled"
                      />
                    )}
                  />

                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-16"
                        label="Password"
                        type="password"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        variant="filled"
                        InputProps={{
                          className: 'pr-2',
                          type: showPassword ? 'text' : 'password',
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                                <Icon className="text-20" color="action">
                                  {showPassword ? 'visibility' : 'visibility_off'}
                                </Icon>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                    )}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    className="w-full mx-auto mt-24"
                    aria-label="LOG IN"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    value="legacy"
                  >
                    Login
                  </Button>
                </form>

              </div>
            </CardContent>
          </Card>
        </motion.div>
      </div>

      <SnackbarMessage />

    </div >
  );
}

export default Login;
