const locale = {
  TITLE: 'Pagina degli operatori',
  EMAIL: 'Email',
  NAME: 'Nome',
  UID: 'UID',
  PHONE: 'Telefono',
  ROLE: 'Ruolo',
  EDIT_OPERATOR: 'Modifica l\'operatore',
  OPERATOR_BADGE: "Badge dell'operatore",
  OPERATORS_PER_PAGE: 'Operatori per pagina',
  SORT: "Ordina",
  SEARCH: "Cerca per nome",
  NEW_OPERATOR: "Nuovo operatore",

  /* NEW OPERATOR DIALOG */
  NEW_OPERATOR_DIALOG_TITLE: "Aggiungi un operatore a ",
  FORM_OPERATOR_NAME_LABEL: "Nome",
  FORM_OPERATOR_ROLE_LABEL: "Ruolo",
  FORM_OPERATOR_EMAIL_LABEL: "Email",
  FORM_OPERATOR_EMAIL_HELPER: "L'email di contatto verrà utilizzata anche come credenziale di accesso",
  FORM_OPERATOR_PHONE_LABEL: "Numero di telefono",
  FORM_OPERATOR_EMAIL_CONFIRMATION_LABEL: "Conferma Email di contatto",
  FORM_OPERATOR_PASSWORD_LABEL: "Password",
  FORM_OPERATOR_PASSWORD_CONFIRMATION_LABEL: "Conferma Password",
  NEW_OPERATOR_ERROR_MESSAGE: "Impossibile creare l'operatore.",
  NEW_OPERATOR_SUCCESS_MESSAGE: "Operatore creato correttamente.",
  
  /* EDIT OPERATOR DIALOG */
  EDIT_OPERATOR_DIALOG_TITLE: "Modifica operatore",
  EDIT_OPERATOR_ERROR_MESSAGE: "Impossibile modificare l'operatore.",
  EDIT_OPERATOR_SUCCESS_MESSAGE: "Operatore modificato correttamente.",

  /* OPERATOR BADGE */
  COMPANY_NAME: "Nome dell'azienda",
  SANI_CODE: 'SANICode',
  DOWNLOAD: 'Download',
  DOWNLOAD_PDF: 'Download PDF',
  GENERATE_PDF: 'Genera PDF',
};

export default locale;
