import { Alert, Snackbar } from "@mui/material";
import withReducer from "app/store/withReducer";
import { useDispatch, useSelector } from "react-redux";
import reducer from "./store";
import { closeSnackbar } from "./store/snackbarSlice";

export const SNACKBAR_TYPE = {
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
}

function SnackbarMessage(props) {
    const dispatch = useDispatch();
    const snackbar = useSelector(({ snackbarMessage }) => snackbarMessage.snackbar);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(closeSnackbar());
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={snackbar.open}
            autoHideDuration={4000}
            onClose={handleClose}
            style={{ maxWidth: '350px' }}
        >
            <Alert
                severity={snackbar.type === "ERROR" ? "error" : snackbar.type === "SUCCESS" ? "success" : "info"}
                variant="filled"
                style={{ borderRadius: '20px', padding: '12px 20px', width: '100%', boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px" }}
                onClose={handleClose}
            >
                {snackbar.message || undefined}
            </Alert>
        </Snackbar>
    )
}

export default withReducer('snackbarMessage', reducer)(SnackbarMessage);
