import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import it from './i18n/it';
import { authCapabilityMasks, authRoles } from 'app/auth';

i18next.addResourceBundle('en', 'historyPage', en);
i18next.addResourceBundle('it', 'historyPage', it);

const HistoryConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    auth: {
        roles: authRoles.admin,
        capabilities: [authCapabilityMasks.deviceDistributor, authCapabilityMasks.bottleDistributor],
    },
    routes: [
        {
            path: '/history',
            component: React.lazy(() => import('./History'))
        }
    ]
};

export default HistoryConfig;
