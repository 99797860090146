import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import store from "app/store";
import { URL } from "utils/env/dev/constants";

class ApiService extends FuseUtils.EventEmitter {

  geocodingAddress = (addr) => {
    return axios.get(
      'https://nominatim.openstreetmap.org/search',
      {
        params: {
          q: addr,
          format: 'json'
        },
        headers: {          
          'Accept': '*/*',
        }
      }
    )
  }


  /* Util - POST request */
  postRequest = (path, cmd, payload) => {
    const option_language = store.getState().auth.user.option_language
    const langHeader = option_language ? { 'Accept-Language': store.getState().auth.user.option_language } : {}
    
    console.log(`postRequest - URL: ${URL}, path: ${path}, cmd: ${cmd}, user_type: OPERATOR, token: ${store.getState().auth.user.auth_token}, user_id: ${store.getState().auth.user.id}, payload: ${JSON.stringify(payload)}`);
    return axios.post(
      URL,
      JSON.stringify({
        path: path,
        cmd: cmd,
        user_type: "OPERATOR",
        token: store.getState().auth.user.auth_token,
        user_id: store.getState().auth.user.id,
        payload: payload,
      }),
      {
        headers: langHeader
      }
    );
  }

  /* AMQP */
  sendAmqpCmd = (amqpPayload) => {
    return this.postRequest(
      "amqp",
      "sendAmqpCmd",
      amqpPayload
    );
  }

  recvAmqpReply = (cmdUuid) => {
    return this.postRequest(
      "amqp",
      "recvAmqpReply",
      {
        cmd_uuid: cmdUuid
      }
    );
  }

  /* Stats (dashboard) */
  getStats = () => {
    return this.postRequest(
      "stats",
      "getStats",
      {}
    );
  }

  getDeviceStats = (deviceId) => {
    return this.postRequest(
      "stats",
      "getDeviceStats",
      {
        device_id: deviceId
      }
    );
  }

  getLiquidStats = () => {
    return this.postRequest(
      "stats",
      "getLiquidStats",
    );
  }


  /* Sessions */
  getSessions = (payload) => {
    return this.postRequest(
      "sessions",
      "getSessions",
      payload
    );
  }  

  /* Devices */
  getAllRelatedDevices = (payload) => {
    return this.postRequest(
      "devices",
      "getAllRelatedDevices",
      payload
    );
  }
  getDevices = () => {
    return this.postRequest(
      "devices",
      "getDevices",
      {}
    );
  }
  getOwnedDevices = () => {
    return this.postRequest(
      "devices",
      "getOwnedDevices",
      {}
    );
  }
  getDeviceById = (deviceId) => {
    return this.postRequest(
      "devices",
      "getDeviceById",
      { id: deviceId }
    )
  }
  getDeviceTransactionTypes = () => {
    return this.postRequest(
      "devices",
      "getDeviceTransactionTypes",
      {}
    );
  }
  getDevicesTransactions = () => {
    return this.postRequest(
      "devices",
      "getDevicesTransactions",
      {}
    );
  }
  getDeviceTransactionsByDeviceId = (deviceId) => {
    return this.postRequest(
      "devices",
      "getDeviceTransactionsByDeviceId",
      { id: deviceId }
    );
  }
  getDeviceStatusList = () => {
    return new Promise(((resolve, reject) => {
      resolve([
        { id: 0, value: 'status-1' },
        { id: 1, value: 'status-2' },
        { id: 2, value: 'status-3' }
      ]);
    }));
  }

  /* Bottles */
  getAllRelatedBottles = (payload) => {
    return this.postRequest(
      "bottles",
      "getAllRelatedBottles",
      payload,
    );
  }
  getBottles = () => {
    return this.postRequest(
      "bottles",
      "getBottles",
      {}
    );
  }
  getOwnedBottles = () => {
    return this.postRequest(
      "bottles",
      "getOwnedBottles",
      {}
    );
  }
  getBottleById = (bottleId) => {
    return this.postRequest(
      "bottles",
      "getBottleById",
      { id: bottleId }
    )
  }
  getBottleTransactionTypes = () => {
    return this.postRequest(
      "bottles",
      "getBottleTransactionTypes",
      {}
    );
  }
  getBottlesTransactions = () => {
    return this.postRequest(
      "bottles",
      "getBottlesTransactions",
      {}
    );
  }
  getBottleTransactionsByBottleId = (bottleId) => {
    return this.postRequest(
      "bottles",
      "getBottleTransactionsByBottleId",
      {
        id: bottleId
      }
    );
  }

  /* Locations */
  getStructures = () => {
    return this.postRequest(
      "locations",
      "getStructures",
      {}
    );
  }

  getStructureStats = (structureId) => {
    return this.postRequest(
      "stats",
      "getStructureStats",
      { structure_id: structureId }
    );
  }

  getStructuresStats = () => {
    return this.postRequest(
      "stats",
      "getStructuresStats",
      {}
    );
  }

  getLocations = (structureId) => {
    return this.postRequest(
      "locations",
      "getLocations",
      { structure_id: structureId }
    );
  }
  getStructureTypes = () => {
    return this.postRequest(
      "locations",
      "getStructureTypes",
      {}
    );
  }
  getLocationTypes = () => {
    return this.postRequest(
      "locations",
      "getLocationTypes",
      {}
    );
  }
  addStructure = (structureData) => {
    return this.postRequest(
      "locations",
      "addStructure",
      structureData
    )
  }
  addLocation = (locationData) => {
    return this.postRequest(
      "locations",
      "addLocation",
      locationData
    )
  }
  updateLocation = (updateData) => {
    return this.postRequest(
      "locations",
      "updateLocation",
      updateData
    )
  }

  /* Operators */
  getOperators = () => {
    return this.postRequest(
      "operators",
      "getOperators",
      {}
    );
  }
  getOperatorsStats = () => {
    return this.postRequest(
      "stats",
      "getOperatorsStats",
      {}
    );
  }
  getOperatorRoles = () => {
    return this.postRequest(
      "operators",
      "getOperatorRoles",
      {}
    );
  }
  addOperator = (operatorData) => {
    return this.postRequest(
      "operators",
      "addOperator",
      operatorData
    )
  }
  updateOperator = (updateData) => {
    return this.postRequest(
      "operators",
      "updateOperator",
      updateData
    )
  }
  setOperatorOptions = (options) => {
    return this.postRequest(
      "operators",
      "setOperatorOptions",
      options
    )
  }

  /* Liquid */
  getLiquid = () => {
    return this.postRequest(
      "liquid",
      "getLiquid",
      {}
    )
  }
  addLiquid = (liquidData) => {
    return this.postRequest(
      "liquid",
      "addLiquid",
      liquidData
    )
  }
  getLiquidById = (liquidId) => {
    return this.postRequest(
      "liquid",
      "getLiquidById",
      { id: liquidId }
    )
  }
  getLiquidTypes = () => {
    return this.postRequest(
      "liquid",
      "getLiquidTypes",
      {}
    )
  }
  getLiquidSuppliers = () => {
    return this.postRequest(
      "liquid",
      "getLiquidProducers",
      {}
    )
  }
  addLiquidSupplier = (supplierData) => {
    return this.postRequest(
      "liquid",
      "addLiquidProducer",
      supplierData
    )
  }
  getLiquidTransactionsByLiquidId = (liquidId) => {
    return this.postRequest(
      "liquid",
      "getLiquidTransactionsByLiquidId",
      { id: liquidId }
    )
  }
  generateAddBottlesToken = (tokenData) => {
    return this.postRequest(
      "bottles",
      "generateAddBottlesToken",
      tokenData
    );
  }

  /* Social */
  getSubCompanies = () => {
    return this.postRequest(
      "social",
      "getSubCompanies",
      { type: 'both' }
    );
  }
  getConnectableCompanies = (searchString) => {
    return this.postRequest(
      "social",
      "getConnectableCompanies",
      { search_string: searchString }
    );
  }
  getSearchableAndAssignableSubCompanies = (searchString) => {
    return this.postRequest(
      "social",
      "getSearchableAndAssignableSubCompanies",
      { search_string: searchString }
    );
  }
  getAllCompanies = (searchString) => {
    return this.postRequest(
      "social",
      "getAllCompanies",
      { search_string: searchString }
    );
  }
  connectTo = (companyCode) => {
    return this.postRequest(
      "social",
      "connectTo",
      { company_code: companyCode }
    )
  }
  addInvitation = (companyData) => {
    return this.postRequest(
      "social",
      "addInvitation",
      companyData
    )
  }
  getInvitation = (invitationToken) => {
    return this.postRequest(
      "social",
      "getInvitation",
      {
        inv_token: invitationToken
      }
    )
  }
  completeRegistration = (registrationData) => {
    return this.postRequest(
      "social",
      "completeRegistration",
      registrationData
    )
  }
  getCompanyCapabilitiesList = () => {
    return this.postRequest(
      "social",
      "getCompanyCapabilitiesList",
      {}
    )
  }

  /* Common APIs */
  assignMultipleTransaction = (assignmentData) => {
    return this.postRequest(
      "social",
      "commitMultipleTransactions",
      {
        ...assignmentData,
        status: 0
      }
    );
  }

}

const instance = new ApiService();

export default instance;
