const locale = {
  COMPANY_DETAILS: "Company details",
  OPERATOR_DETAILS: "Admin operator details",
  DATA_CHECK: "Data check",

  FORM_COMPANY_NAME_LABEL: "Company name",
  INV_CODE_HELPER: "Code received with the invitation email",
  FORM_VAT_ID_LABEL: "VAT ID",
  FORM_ADDRESS_LABEL: "Registered office address",

  FORM_OPERATOR_NAME_LABEL: "Operator name",
  FORM_CONTACT_EMAIL_LABEL: "Contact email",
  CONTACT_EMAIL_HELPER: "The contact email will also be used as login credential",
  FORM_PHONE_LABEL: "Phone number",
  FORM_PASSWORD_LABEL: "Password",
  FORM_PASSWORD_CONFIRMATION_LABEL: "Password confirmation",

  COMPANY_NAME: "Company name",
  VAT_ID: "VAT ID",
  ADDRESS: "Registered office address",
  ADMIN_NAME: "Admin name",
  ADMIN_EMAIL: "Admin email",
  FORM_INV_CODE_LABEL: "Invitation code",

  BACK: 'Back',
  NEXT: 'Next',
  FINISH: 'Finish',
};

export default locale;
