const locale = {
  TITLE: 'Pagina delle sessioni',
  OPERATOR: 'Operatore',
  LOCATION: 'Ambiente',
  STATUS: 'Stato',
  DATE: 'Data',
  SESSIONS_PER_PAGE: 'Sessioni per pagina',
  REPORT_DOWNLOAD: 'Scarica il report',
  SORT: "Ordina",
};

export default locale;
