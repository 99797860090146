const locale = {
  TITLE: 'Liquid Page',
  SEARCH_BY_LOT_CODE: 'Search by lot code',
  ADD_LIQUID: 'Add liquid',
  SUPPLIERS: 'Suppliers',

  /* Liquid table */
  LIQUID_PER_PAGE: 'Liquid per page',
  LOT_CODE: 'Lot code',
  TYPE_NAME: 'Liquid type',
  SUPPLIER: 'Supplier',
  STATUS: 'Status',
  EXPIRATION: 'Expiration',
  DERIVED: 'Derived',
  REMAINING: 'Remaining',
  LIQUID_DILUTED: 'The liquid is diluted',
  MORE_DETAILS: 'More details',
  DILUTE_LIQUID: 'Dilute the liquid',
  GENERATE_BOTTLE: 'Generate bottle',

  /* Liquid dialog */
  NEW_LIQUID_DIALOG_TITLE: 'Add lot of liquid to ',
  FORM_LIQUID_SUPPLIER_LABEL: 'Liquid supplier',
  FORM_LIQUID_TYPE_LABEL: 'Liquid type',
  FORM_LOT_CODE_LABEL: 'Lot code',
  FORM_LIQUID_VOLUME_LABEL: 'Volume (mL)',
  NEW_LIQUID_ERROR_MESSAGE: "Unable to create the liquid.",
  NEW_LIQUID_SUCCESS_MESSAGE: "Liquid succesfully created.",

  /* Dilute liquid dialog */
  DILUTE_LIQUID_DIALOG_TITLE: 'Dilute the lot of liquid',
  AVAILABLE_LIQUID: 'Available liquid',
  FORM_EXPIRATION_DATE_LABEL: 'Expiration Date',
  FORM_USE_DEFAULT_EXPIRE_DATE_LABEL: 'Use expiration date of the original liquid',
  DILUTE_LIQUID_ERROR_MESSAGE: "Unable to dilute the liquid.",
  DILUTE_LIQUID_SUCCESS_MESSAGE: "Liquid succesfully diluted.",
  DILUTE: 'Dilute',
  
  /* Liquid suppliers page */
  SUPPLIERS_TITLE: 'Suppliers page',
  ADD_SUPPLIER: 'Add supplier',
  SUPPLIERS_PER_PAGE: 'Suppliers per page',
  SUPPLIER_NAME: 'Supplier name',
  SUPPLIER_VAT_ID: 'Supplier VAT ID', 
  SEARCH_BY_NAME: 'Search by name',

  /* Liquid supplier dialog */
  ADD_SUPPLIER_DIALOG_TITLE: 'Add liquid supplier',
  FORM_VAT_ID_LABEL: 'VAT ID',
  FORM_BUSINESS_NAME_LABEL: 'Business name',
  ADD_SUPPLIER_ERROR_MESSAGE: "Unable to add the supplier.",
  ADD_SUPPLIER_SUCCESS_MESSAGE: "Supplier succesfully added.",

  /* Generate bottles dialog */
  GENERATE_BOTTLES_DIALOG_TITLE: "Generate bottles",
  GENERATE_BOTTLES_DIALOG_HEADER: "Continue on smartphone",
  GENERATE_BOTTLES_DIALOG_DETAILS: "Log in to the app with your credentials, scan the QR code on the side and start reading the RFID tags on the bottles.",
  GENERATE_BOTTLES_TOKEN_ERROR_MESSAGE: "Unable to generate the QR Code.",

  /* Liquid details */
  LIQUID_TRANSACTION_DESTINATION_TYPE: "Destination type",
  LIQUID_TRANSACTION_DATE: "Date",
  LIQUID_TRANSACTION_ORIGINAL_VOLUME: "Original volume (mL)",
  LIQUID_TRANSACTION_FINAL_VOLUME: "Final volume (mL)",
  LIQUID_TRANSACTION_DESTINATION_LOT: "Destination lot",
  LIQUID_TRANSACTION_DILUTION: "Dilution",
  LIQUID_TRANSACTION_BOTTLING: "Bottling",

  DESCRIPTION: "Description",
  DILUTION: "Dilution",
  COMPOSITION: "Composition",
  VOLUME: "Volume",
  ADD_TIME: "Add time",
  EXPIRE_DATE: "EXPIRE_DATE",
};

export default locale;
