import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { URL, CONTEXT_PATH } from "utils/env/dev/constants";
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          console.log(err);
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(CONTEXT_PATH + '/auth/register', data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    const url = URL;
    const data = JSON.stringify({
      path: "auth",
      cmd: "login",
      user_type: "OPERATOR",
      token: "",
      user_id: -1,
      payload: {
        email: email,
        password: password,
        uid: "",
        cid: "",
        token: "",
        method: "EMAIL_PASS",
        sw_version: "",
        type_id: 0
      }
    })

    return new Promise((resolve, reject) => {
      axios
        .post(url, data,
          {
            headers: {
              'Content-Type': 'application/json',
            }
          })
        .then((res) => {
          let response = res.data;

          if (response.return == 0) {
            this.setSession(response.data.auth_token, response.data);
            resolve(response.data);
          } else {
            reject(response);
          }
        });
    });
  };

  signInWithToken = () => {
    const access_token = this.getAccessToken();
    /* not implemented in back-end. Retrieve user data from localStorage */
    return new Promise((resolve, reject) => {
      if (!!access_token) {
        resolve(this.getUserData());
      } else {
        this.logout();
        reject(new Error('Failed to login with token.'));
      }
    })
    /*
    return new Promise((resolve, reject) => {
      axios
        .get('/api/auth/access-token', {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            resolve(response.data.user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
    */
  };

  updateUserData = (user) => {
    return axios.post(CONTEXT_PATH + '/auth/user/update', {
      user,
    });
  };

  setSession = (access_token, user = null) => {
    if (access_token) {
      const user_data = user || this.getUserData()

      localStorage.setItem('jwt_access_token', access_token);
      localStorage.setItem('user_data', JSON.stringify(user_data));

      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('user_data');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  updateSession = (user) => {
    localStorage.setItem('user_data', JSON.stringify(user));
  }

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    /* The token never expires, return true (it is valid) 
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }
    */

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };

  getUserData = () => {
    return JSON.parse(window.localStorage.getItem('user_data'));
  }
}

const instance = new JwtService();

export default instance;
